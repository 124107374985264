.footer-brands{
  max-width: 100%;
  background-color: #fff;
  height: 120px;
  box-shadow: 0px -10px 25px  rgba(0,0,0,0.1),
              0px 10px 25px  rgba(0,0,0,0.1);
  position: relative;
  z-index: 150;
  display: none;

  @include respond-to('medium'){
    height: unset;
  }

  .footer-brands-container{
    max-width: 1150px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    margin: 0 auto;
    top: 40%;
    transform: translateY(-40%);

    @include respond-to('medium'){
      flex-direction: column;
      align-items: center;
      top: unset;
      transform: none;
    }
  }

  .footer-brands-images{
    display: inline-block;
    margin: 0 auto;
    position: relative;
    bottom: 10px;
    padding:0px 7px;

    @include respond-to('medium'){
      padding: 20px 0;
      max-width: 60%;
      bottom: unset;
    }

    .brand-image{
      display: inline-block;

      &:hover{

      }
    }
  }

  h3{
    position: relative;
    //top: -20px;
    display: inline-block;
    color: #000;
    font-size: 30px;
    padding: 0px 20px;
    font-weight: 700;
    white-space: nowrap;

    @include respond-to('medium'){
      font-size: 20px;
      padding: 0px 10px;
    }
  }

}
