body.scrolling .header-mobile{
  @include respond-to('mid-large'){
    display: block;
  }
}

.header-mobile{
  width: 100%;
  height: 50px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
  padding: 10px 15px;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);

  #header-logo-mobile{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 70%;
    margin-left: 10px;
  }

  .header-phone{
    width: 120px;
    padding-top: 3px;
    display: inline-block;
    margin-right: 20px;

    span{
      color: #6b7379;
      font-size: 18px;
      font-weight: bold;
      margin-right: 10px;
      vertical-align: middle;
    }

    img{
      filter: grayscale(100%);
    }
  }

  .mobile-menu-btn{
    float: right;
    //margin-right: 15px;
  }

  @include respond-to('medium'){
    display: block;
  }
}
