.home-products{
  width: 100%;
  background-color: #fff;
  position: relative;

  #home-box-2{
    padding-top: 30px;
    padding-bottom: 60px;
    max-width: 1200px;
    margin: 0 auto;

    @include respond-to('medium'){
      height: auto;
    }

    h3, h1{
      position: relative;
      text-align: center;
      //top: 30px;
      margin: 30px;
      //left: 50%;
      //transform: translateX(-50%);
      //display: block;
      color: #000;
      font-size: 30px;
      line-height: 35px;
      font-weight: 700;

      @include respond-to('medium'){
        font-size: 18px;
        margin: 0;
      }
    } //h3
  } //#home-box-2
} //home-products

.on-sale{
  &:after{
    content: url(../images/tag_sale.png);
    position: absolute;
    top: -6px;
    left: -6px;
    z-index: 99;
  }
}
.home-product{
  height: 420px;
}



.home-product, .home-subcat{
  width: 270px;
  position: relative;
  text-align: center;
  margin-bottom: 25px;
  background-color: #fff;
  border: solid 1px #dedede;
  transition: box-shadow 0.2s;

  .home-product-lower{
    &:hover{
      background-color: #fff;
    }
  }

  .home-image-container{
    width: 210px;
    height: 200px;
    position: relative;
    border-bottom: solid 1px #dedede;
    margin: 0 auto;

    @include respond-to('medium'){
      width: 100%;
      height: 80px;
    }

    img{
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translateX(50%) translateY(-50%);
    }
  } //home-image-container

  @include respond-to('medium'){
    width: 125px;
    height: 250px;
    margin-bottom: 45px;
  }

  .home-product-title{
    margin: 12px 0 5px;
    font-size: 20px;
    font-weight: 700;
    display: block;
    height:48px;
    vertical-align: middle;
    text-align: center;
    position: relative;

    @include respond-to('medium'){
      height: unset;
      font-size: 14px;
      margin-top: 20px;
    }

    &>.text{
      display: inline;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      padding: 0px 30px;

      @include respond-to('medium'){
        padding: 0 8px;
      }
    }
  } //home-product-title

  .home-product-description{
    width: 90%;
    display: block;
    margin: 0 auto;
    font-size: 16px;
    color: #777777;
    height: 42px;

    @include respond-to('medium'){
      width: 95%;
      font-size: 12px;
      position: relative;
      top: 25px;
    }
  } //home-product-description

  .home-product-price{
    color: #000;
    font-weight: 700;
    font-size: 16px;
    display: block;
    margin-top: 10px;

    @include respond-to('medium'){
      font-size: 12px;
      margin-top: 40px;
    }

    i{
      font-size: 13px;
    }
  } //home-product-price

  .home-product-btn{
    background-color:#fff;
    border: solid 1px;
    border-radius: 4px;
    position: relative;
    display: block;
    width: 125px;
    height: 40px;
    margin: 20px auto;
    transition: 0.2;

    @include respond-to('medium'){
      width: 110px;
      height: 30px;
      margin: 10px auto;
    }

    .home-btn-cart{
      width: 17px;
      height: 15px;
      position: absolute;
      right: 8px;
      top: 10px;

      @include respond-to('medium'){
        top: 5px;
      }
    }

    span{
      font-size: 16px;
      font-weight: 700;
      display: block;
      height: 100%;
      width: 90px;
      border-right: solid 1px;
      position: absolute;
      left: 0;
      padding-top: 7px;
      top:0;

      @include respond-to('medium'){
        font-size: 12px;
        width: 69px;
        left: 6px;
        padding-top: 5px;
      }
    }
  }

  &:hover{
    box-shadow: 0 0 25px rgba(0,0,0,0.15);
    border-color:#fff;
  } //hover
} //home-product

.home-subcat{
  @include respond-to('medium'){
    margin-bottom: 25px;
    height: unset;
  }
}

.home-products-container{
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 60px 0 0;

  @include respond-to('medium'){
    padding: 0 5%;
    margin-top: 20px;
  }
} //home-products-container
