.product-main{
  max-width: 1200px;
  position: relative;
    top: -90px;

  h1{
    font-size: 30px;
    font-weight: 700;
    color: #000;
  }
}

#lightgallery{
  height: 100%;
}

.product-full-page{
  width: 100%;
  position: relative;

  .product-num{
    color: #777777;
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 20px;

    @include respond-to('medium'){
      font-size: 12px;
    }
  }
  .product-main-info{
    width: 100%;
    padding-bottom: 50px;
    border-bottom: solid 1px #dedede;

    .product-info-side{
      max-width: 565px;
      position: relative;
      float: right;
      margin-left: 30px;
      padding-bottom: 30px;
      border-bottom: solid 1px #dedede;

      @include respond-to('medium'){
        margin-left: 10px;
      }

      h2{
        font-weight: 700;
        font-size: 30px;
        margin-bottom: 0px;
        margin-top: 0px;

        @include respond-to('medium'){
          font-size: 20px;
        }
      }

      .product-info-text{
          font-size: 18px;
          color: #000;
          display: block;
          width: 100%;
          font-weight: 500;

          p{
            font-size: inherit;
          }

          @include respond-to('medium'){
            font-size: 14px;
          }
      }

      .product-add-cart{
        width: 200px;
        height: 60px;
        float: right;
        border-radius: 3px;
        color: #fff !important;
        font-size: 26px;
        font-weight: 700;
        padding-top: 0px;
        padding-left: 20px;
        text-align: left;
        position: relative;
        bottom: 0px;

        @include respond-to('medium'){
          width: 170px;
          height: 45px;
          font-size: 20px;
        }

        &:active{
          box-shadow: 0px 0px 50px rgba(0,0,0,0.5) inset;

        }

        &:before{
          content: url(../images/secure.png);
          position: absolute;
          left: -40px;
          transform: translateX(-100%);

          @include respond-to('medium'){
            left: -15px;
          }
        } //before

        &:after{
          content: url(../images/cart_big.png);
          position: absolute;
          right: 20px;
          top: 50%;
          height: 100%;
          transform: translateY(-50%);
          border-left: solid 2px #fff;
          padding-left: 15px;
          padding-top: 15px;

          @include respond-to('medium'){
            padding-top: 6px;
          }
        } //after
      } //product-add-cart
    } //product-info-side

    .product-image-side{
      max-width: 600px;
      height: 490px;
      margin-right: 0px;
      position: relative;
      float: left;
      text-align: center;
      border: solid 1px #dedede;
      border-radius: 4px;

      @include respond-to('medium'){
        height: 300px;
      }

      img{
        top: 0;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.product-more{
  h2{
    margin: 35px 0;
    font-size: 24px;
    font-weight: 400;
  }
}

.product-selector{
  width: 290px;
  height: 41px;
  //overflow: hidden;
  box-shadow: 0 0 15px rgba(0,0,0,0.30);
  position: relative;
  z-index: 10;
  //outline: none;
  margin: 30px 0;

  &:active{
    box-shadow: 0px 0px 2px rgba(0,0,0,0.50);
    top:1px;
    left: 1px;
  }

  .product-selector-arrow{
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    z-index: 5;
    pointer-events: none;

    &:before{
      content: '';
      width: 7px;
      height: 20px;
      position: absolute;
      left: 0px;
      transform: translateX(-100%);
      top: 0px;
      background-color: #fff;
      z-index: 4;
    }

    &:after{
      content: '';
      border: solid 2px #fff;
      border-top-color: transparent;
      border-left-color: transparent;
      position: absolute;
      top: 2px;
      left: 5px;
      width: 10px;
      height: 10px;
      z-index: 5;
      transform: rotate(45deg);
    } //after
  } //product-selector-arrow

  select{
    width: 100%;
    height: 100%;
    border: none;
    //outline: none;
    color: #000;
    font-size: 18px;
    padding-right: 10px;
    line-height: 18px;
  } //select
} //product-selector

.product-price{
  margin-bottom: 20px;
  .product-old-price{
    font-size: 16px;
    color: #777777;
    text-decoration: line-through;
    display: block;

    i{
      text-decoration: line-through;
      font-size: 13px;
    } //i
  } //product-old-price

  .product-price-title{
    font-size: 24px;
    font-weight: 700;

    @include respond-to('medium'){
      font-size: 20px;
    }
  } //product-price-title

  .product-price-sum{
    font-size: 24px;
    font-weight: 700;
    color: #000;

    @include respond-to('medium'){
      font-size: 20px;
    }

    i{
      font-size: 20px;

      @include respond-to('medium'){
        font-size: 16px;
      }
    }
  } //product-price-sum
} //product-price
