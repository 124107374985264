// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------
.product-base-green{
  @include product-color(#166b27);
}

.product-base-red{
  @include product-color(#f05351);
}

.product-base-brown{
  @include product-color(#7f6f5d);
}

.product-base-lime{
  @include product-color(#8bc756);
}

.product-base-orange{
  @include product-color(#fbab18);
}

.product-base-blue{
  @include product-color(#387593);
}

.base-color-green{
  @include cat-color(#166b27);
}

.base-color-red{
  @include cat-color(#f05351);
}

.base-color-brown{
  @include cat-color(#7f6f5d);
}

.base-color-lime{
  @include cat-color(#8bc756);
}

.base-color-orange{
  @include cat-color(#fbab18);
}

.base-color-blue{
  @include cat-color(#387593);
}

.section-full{
  width:100%;
}

.section-limit{
  width:100%;
  max-width:$max-width;
  margin: 0 auto;
}

.section-spacer{
  width:100%;
  height:$section-spacer-height;
}

.row-spacer{
  width:100%;
  float:right;
  height:$row-spacer-height;
}

img{
  max-width:100%;
}

ul{
  padding: 0;
  list-style-type: none;
}

.img-not-responsive{
  max-width:auto;
}

.no-padding{
  padding:0;
}

.no-padding-2{
  padding:0;
}

.no-margin-mobile{
  @include respond-to('medium') {
    margin:0px 15px;
  }
}

.no-margin{
  margin:0;
}


.helper-blue{
  width:100%;
  min-height:250px;
  background-color:blue;
  opacity:0.5
}

.helper-red{
  width:100%;
  min-height:250px;
  background-color:red;
  opacity:0.5
}

.helper-green{
  width:100%;
  min-height:250px;
  background-color:green;
  opacity:0.5
}

.hr-full{
  margin:0;
  padding:0;
  border:0;
  width:100%;
  height:$hr-full-height;
  background-color:$hr-full-bg;
  border-bottom:1px solid $hr-border-color;
  margin:$hr-margin;
}

.no-row-padding{
    margin:0;
    & > *{
      padding:0;
    }
}

.no-margin-bottom{
  margin-bottom:0 !important;
}

iframe{
  max-width:100% !important;
}

.full-page-wrapper{
  width: 100%;
  //min-height: 800px;
  background-color: #fff;
  padding-bottom: 20px;
  position: relative;
  z-index: 100;
}

.page-h-separator{
  width: 100%; 
  height: 2px;
  box-sizing: border-box;
  border-top: solid 1px #cfcfcf;
  border-bottom: solid 1px #fff;
  margin: 3px 0;
}

.addtocartdummy{
  position: fixed;;
  z-index: 100000000000000000000;
}
