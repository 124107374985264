.catalog-wrapper{
  padding:35px $padding-base 50px;

  @include respond-to('medium'){
    padding: 15px 15px 80px;
  }

  .catalog-main{

    h1{
      @include respond-to('medium'){
        font-size: 14px;
        margin-top: 0;
      }
    }

    .catalog-title{
      margin: 0 0 40px;
      font-size: 30px;
      font-weight: 700;
      color: #000;
      text-align: center;
      display: block;

      h1{
        margin: 0 0 40px;
        font-size: 30px;
        font-weight: 700;
        color: #000;
        text-align: center;
        display: block;
        margin: 0;
      }
    }
  }
}

.hover-color{
  @at-root #{&}_green{
    @include hover-color(#166b27);
  } //_green
  @at-root #{&}_red{
    @include hover-color(#f05351);
  } //_red
  @at-root #{&}_brown{
    @include hover-color(#7f6f5d);
  } //_brown
  @at-root #{&}_lime{
    @include hover-color(#8bc756);
  } //_lime
  @at-root #{&}_orange{
    @include hover-color(#8E0E13);
  } //_orange
  @at-root #{&}_blue{
    @include hover-color(#387593);
  } //_blue
} //hover-color
