body.scrolling .header-desktop, body.small-header .header-desktop{
  height: 80px;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  

  .header-wrapper{
    padding: 20px 15px 0px;
  }

  .header-bg{
    background: linear-gradient(to bottom, #fff 0, #fff 100%);
    opacity: 1;
  }

  .header-logo{
    max-width: 150px !important;
  } //header-logo
}

header{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;

  .header-desktop{
    height: 250px;
    display: block;
    transition: 0.25s;

    @include respond-to('medium'){
      display: none;
    }

    .header-bg{
      width: 100%;
      height: 100%;
      opacity: 0.75;
      background: linear-gradient(to bottom, #fff 0, #fff 40%, transparent 100%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    .header-wrapper{
      max-width: 2000px;
      width: 100%;
      padding: 50px 40px;
      height: 100%;
      margin: 0 auto;
      position: relative;
      transition: 0.2s;

      nav{
        display: inline-block;
        ul{
          li{
            display: inline-block;
            padding: 2px 10px 5px;
            border-radius: 3px;
            border: solid 1px transparent;
            box-sizing: border-box;
            transition: 0.3s;

            a{
              font-size: 22px;
              font-weight: 500;

            } //a
            &.header-selected{
              background-color: #8E0E13;

              a{
                  color:#fff;
              } //a
            } //header-selected
            &:hover{
              border-color: #8E0E13;

              .header-sub-menu{
                display: block;
              }

              a{

              } //a
            } //hover

            .header-sub-menu{
              position: absolute;
              background-color: #fff;
              background: linear-gradient(to left, #fff 0, #fff 33%, transparent 75%);
              right: 0;
              bottom: -5px;
              width: 100%;
              transform: translateY(100%);
              display: none;

              &:after{
                content: '';
                width: 100%;
                position: absolute;
                top: -30px;
                right: 0;
                height: 50px;
                background-color: transparent;
                z-index: -1;
              }

              li{

                &:hover{
                  background-color: #8E0E13;
                  border-radius: 0;
                }
                a{
                  font-size: 18px;

                }
              }
            } //header-sub-menu
          } //li
        } //ul
      } //nav

.header-search-container{
  display: inline-block;
  position: relative;

  .product-glass{
    display: inline-block;
    max-width: initial;
    position: absolute;
    bottom: 7px;
    margin-right: 10px;
    z-index: 1001;
  }

  form{
    display: inline-block;

    input[type="text"]{
      width: 0px;
      border-radius: 4px;
      padding: 5px 0px;
      box-shadow: 0 0 7px rgba(0,0,0,0.3), 0 0 5px rgba(0,0,0,0.3) inset;
      transition: width 1s, opacity 0.5s;
      opacity: 0;
      color: #000;
      border:0;
      outline: none;
      //visibility: hidden;
    } //input text

    &.header-form-display{
      input[type="text"]{
        width: 250px;
        padding: 5px 32px 5px 10px;
        border: solid 1px #fff;
        opacity: 1;
        background-color: transparent;
      } //input text
      input[type="submit"]{
        //display: block;
      } //input submit
    } //header-form-display
    input[type="submit"]{
      width: 20px;
      height: 20px;
      background-color: #000;
      opacity: 0;
      position: absolute;
      bottom: 7px;
      right: -2px;
      margin-right: 10px;
      z-index: 1002;
      display: none;
    } //input submit
  } //form
} //header-search-container

    /*  .header-search-container{
        position: relative;
        width: 330px;
        margin-left: 20px;

        &:after{
          content: url(/assets/images/header_search_icn.png);
          position: absolute;
          left: 12px;
          top: 11px;
        } //after

        .header-search-form{
          margin-top: 19px;

          input[type="text"]{
            width: 290px;
            background-color: #f6f6f6;
            border-left: none;
            border-radius: 0px 3px 3px 0px;
            padding-right: 13px;
            padding-top: 2px;
          } //input[type="text"]

          input[type="submit"]{
            width: 40px;
            background-color: transparent;
            border-radius: 3px 0px 0px 3px;
            position: relative;
            z-index: 10;
          } //input[type="submit"]

          input{
            height: 38px;
            margin: 0;
            display: inline-block;
            float: right;
            border: solid 1px #d6d6d6;
            box-shadow: 1px 3px 5px rgba(0,0,0,0.1) inset;
          } //input

          ::-webkit-input-placeholder {
            color: #6b7379;
            font-size: 18px;
          } //placeholder

          ::-moz-placeholder {
            color: #6b7379;
            font-size: 18px;
          } //placeholder

          :-ms-input-placeholder {
            color: #6b7379;
            font-size: 18px;
          } //placeholder

          :-moz-placeholder {
            color: #6b7379;
            font-size: 18px;
          } //placeholder
        } //header-search-form
      } //header-search-container*/

      .header-logo{
        float: left;
        position: relative;
        bottom: 10px;
        transition: 0.2s;
        max-width: 100%;
      } //header-logo

      .header-shopping-cart{
        display: inline-block;
        margin-right: 50px;
        vertical-align: top;
        padding-top: 6px;
        position: relative;

        .header-shopping-circle{
          position: absolute;
          width: 18px;
          height: 18px;
          background-color: #e41e26;
          border-radius: 9px;
          top: 2px;
          right:15px;
          text-align: center;

          span{
            color: #fff;
            font-size: 13px;
            font-weight: bold;
            vertical-align: top;
          } //span
        } //header-shopping-circle
      } //header-shopping-cart
    }  //header-wrapper
  } //header-desktop
} //header
