.content-banner{
  @include respond-to('medium'){
    margin-top: 50px;
  }
  img{
    max-width: 100%;
    height: auto;
  }
}

.content-hugger{
  max-width: 100%;
  margin: 0px auto 80px;
  // box-shadow: 0 -6px 15px rgba(0,0,0,0.1);
  position: relative;

  .content-main-section{
    max-width: 1200px;
    margin: 0 auto;

    h1{
      color: #000;
      font-size: 35px;
      font-weight: 700;
      text-align: center;
      border-bottom: solid 1px #dedede;
      padding-bottom: 30px;
      margin-bottom: 30px;
      margin-top: 0;
      padding-top: 20px;

      span{
        color: #8E0E13;
      }
    }
    .content-text-section{
      color: #000;
      font-weight: 500;

      @include respond-to('medium'){
        padding: 15px;
      }

      img{
        float: left;
        margin: 0 20px 50px 0;

        @include respond-to('medium'){
          height: auto;
        }
      }

      p{
        margin-bottom: 20px;
        font-size: 20px;

        @include respond-to('medium'){
          margin-bottom: 15px;
          font-size: 14px;
          line-height: 20px;
        }

        &:last-of-type{
          margin-bottom: 100px;

          @include respond-to('medium'){
            margin-bottom: 40px;
          }
        }
      }
      h2{
        color: #8E0E13;
        font-size: 22px;
        font-weight: 700;
      }
      ul{
        font-size: 20px;
        list-style-type: initial;
        margin-right: 20px;
        margin-bottom: 30px;

        li{
          padding-right: 20px;
          line-height: 30px;
        }
      }
    }
  }
}
