.main-banners{
  top: 0;
  left: 0;

  @include respond-to('mid-large'){
    margin-top: 50px;
    position: relative;
  }

  img{
    max-width: 100%;
    left: 0;
    top: 0;

    @include respond-to('mid-large'){

    }
  } //img

  .home-overlay-section{
    position: absolute;
    top: 30%;
    z-index: 100;
    right: 50%;
    text-align: right;
    max-width: 600px;

    @include respond-to('medium'){
      position: absolute;
    top: 140px;
    margin-bottom: 0;
    width: 100%;
    text-align: right;
    } //medium

    h2, .banner-title{
      font-size: 50px;
      font-weight: 800;
      line-height: 50px;
      color: #fff;
      margin-bottom: 10px;
      margin-top: 20px;

      @include respond-to('mid-large'){
        text-shadow: 3px 5px 10px #fff, -3px -5px 10px #fff, 0px 0px 10px #fff;
        line-height: 20px;
        position: absolute;
        top: -150px;
        max-width: 50%;
        font-size: 20px;
      }
    }

    &>span{
      color: #000;
      font-size: 28px;
      font-weight: 400;

      @include respond-to('medium'){
        //color: #000;
        font-size: 14px;
        top: -70px;
        position: absolute;
        width: 50%;
        text-shadow: 1px 3px 5px #fff, -1px -3px 5px #fff, 0px 0px 5px #fff;

      } //medium
    } //span

    a{
      color: #000;
      background-color: #8E0E13;
      font-size: 22px;
      font-weight: 500;
      border-radius: 3px;
      padding: 3px 20px 6px;
      display: inline-block;
      margin-top: 20px;

      @include respond-to('mid-large'){
        font-size: 10px;
        padding: 3px 10px 5px;
        position: absolute;
        right: -33%;
        bottom: 35px;
      }
    } //a
  } //home-overlay-section
} //main-banners

.homepage-hugger{
  width: 100%;
  background-color: #fff;
  position: relative;
  z-index: 50;
  box-shadow: 0 0px 30px rgba(0,0,0,0.4);

  .home-box{
    position: relative;
    height: 150px;
    box-shadow: 0 -10px 20px -5px rgba(0,0,0,0.1) inset;
    width: 100%;
    max-width: 100%;

    @include respond-to('medium'){
      text-align: center;
      padding-top: 0;
      height: 70px;
    }
  }
}

.home-categories{
  padding: 0;
  display: flex;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;

  div{
    text-align: center;
    display: inline-block;
    width: calc(100% / 6);
    height: 100%;
    border-right: solid 1px #d5d5d5;
    border-bottom: solid 3px;
    border-bottom-color: inherit;
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    &:last-of-type{
      border-left: solid 1px #d5d5d5;
    }

    @include respond-to('medium'){
      background-color: #fff;
      width: 100%;
      padding: 5px;
    }

    a{
      margin: 0;
      padding: 0;
      text-align: center;
      height: 100%;
      display: block;
      position: relative;
      color: inherit;

      img{
        display: block;
        margin: 0 auto;
        position: absolute;
        top: 0;
        max-height: 80%;
        bottom: 0;
        right: 0;
        left: 0;
        max-width: 80%;


        @include respond-to('medium'){
          opacity: 0.2;
          top: unset;
          bottom: 0;
          position: absolute;
          max-height: 90%;
          left: 50%;
          transform: translateX(-50%);
        }
      } //img

      span{
        font-size: 20px;
        font-weight: bold;
        color: #000;
        position: absolute;
        bottom: 0px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: inherit;

        @include respond-to('medium'){
          font-size: 10px;
          bottom: unset;
          top: 0;
        }
      } //span
    } //a

    &:hover{
      background-color: auto;

    } //hover
  } //div
} //home-categories
