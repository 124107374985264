footer{
  position: relative;
  left: 0;
  width: 100%;
  background-color: #ececec;
  height: 485px;
  z-index: 155;
  height: auto;
  @include respond-to('medium'){
    height: auto;
  }


  .footer-lower-container, .footer-container{
    width: 1230px;
    background-color: transparent;
    margin: 0 auto;
    position: relative;
  }

  .footer-container{
    padding: 55px $padding-base 0;
    height: 379px;
    display: flex;
    justify-content: space-between;

    @include respond-to('medium'){
      flex-direction: column;
      width: 100%;
      height: auto;
    }

    .footer-links{
      display: flex;

      @include respond-to('medium'){
        flex-wrap: wrap;
        justify-content: center;
      }

      .footer-link-column{
        width: 200px;
        margin-right: 30px;

        @include respond-to('medium'){
          max-width: 135px;
          margin-right: 10px;
        }

        span{
          font-size: 18px;
          color: #4b4b4b;
          font-weight: 500;
          border-bottom: solid 1px #b8b8b8;
          padding-bottom: 25px;
          display: block;
          margin-bottom: 25px;

          @include respond-to('medium'){
            margin-bottom: 0px;
            padding-bottom: 10px;
          }
        }

        ul{
          @include respond-to('medium'){
            margin-bottom: 30px;
          }
          li{
            a{
              font-size: 16px;
              color: #737373;
              line-height: 30px;

              @include respond-to('medium'){
                font-size: 12px;
                line-height: 20px;
              }

              &:hover{
                color: #000;
              }
            }
          }
        }
      }
    }

  }
  .footer-lower-container{
    height: 101px;
    padding: 0 $padding-base;
    position: relative;

    @include respond-to('medium'){
      height: auto;
      max-width: 100%;
      padding-bottom: 50px;
    }

    .footer-address-line{
      color: #000;
      font-size: 16px;
      font-weight: 500;
      vertical-align: middle;

      @include respond-to('medium'){
        display: block;
        text-align: center;
        margin: 0;
      }

      &:first-of-type{
        margin-right: 5px;
      }

      &.after-line{
        &:after{
          content: " | ";
          margin: 0 7px;

          @include respond-to('medium'){
            display: none;
          }
        }
      }

      a{
        color: inherit;
      }
    }
    .footer-soc-images{
      display: inline-block;

      @include respond-to('medium'){
        display: flex;
        justify-content: space-between;
        padding: 0 15% 20px;
      }

      .footer-soc-img{
        margin-left: 10px;
      }
    }
  }

  .footer-h-seperator{
    width: 100%;
    height: 0;
    border-top: solid 1px #b8b8b8;
    border-bottom: solid 1px #fff;
    margin-bottom: 30px;
  }
}
