.home-marketing{
  width: 100%;
  height: 390px;
  background: url(/assets/images/home_box_bg.jpg) center center;

  @include respond-to('medium'){
    height: auto;
    background: url(/assets/images/home_box_bg.jpg);
    margin-top: 130px;
  }


  .home-marketing-wrapper{
    display: flex;
    max-width: 1070px;
    justify-content: space-between;

    @include respond-to('medium'){
      flex-wrap: wrap;
    }

    .home-marketing-box{
      width: 200px;
      text-align: center;

      @include respond-to('medium'){
        width: 40%;
        margin: 5%;
      }

      &>img{
        margin: 60px 0 20px;
        @include respond-to('medium'){
          margin-top: 30px;
        }
      }

      .home-marketing-text{
        span{
          color: #fff;
          font-size: 14px;
          line-height: 24px;

          &:first-of-type{
            font-size: 20px;
            font-weight: bolder;
          }
        }
      }
      .home-marketing-wave{
        img{
          margin-bottom: -8px;
        }
        span{
          font-size: 16px !important;
          font-weight: 400 !important;
          border-bottom: solid 1px #fff;
          padding-bottom: 10px;

          @include respond-to('medium'){
            font-size: 12px !important;
          }
        }
        a{
          font-size: 18px;
          line-height: 30px;
          color: #fff;
          position: relative;
          top: 12px;

          @include respond-to('medium'){
            font-size: 14px;
          }
        }
      }
    }
  }
}
