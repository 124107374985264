.catalog-wrapper{
  //min-height: 1950px;
  h2{
    font-size: 22px;
    font-weight: 700;
    color: #000;
    margin-bottom: 23px;
    margin-top: 40px;
  }
}

.product-box-container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.product-box{
  width: 280px;
  height: 410px;
  margin-top: 20px;
  background-color: #fff;
  position: relative;
  box-shadow: 0 0 30px rgba(0,0,0,0.2);

  .product-image{
    width: 100%;
    border-bottom: solid 1px #dedede;
    position: absolute;
    bottom: 170px;
    text-align: center;
    z-index: 1;

    img{
      max-width: 100%;
    }


  }

  .product-description, .product-price{
    display: block;
    margin: 5px 20px;
  }

  .product-title{
    margin: 250px 20px 5px 20px;
    color: #e41e26;
    font-weight: 700;
    font-size: 20px;
    display: block;
  }

  .product-description{
    color: #777777;
    font-size: 16px;
    margin-bottom: 0;
  }

  .product-price{
    font-size: 26px;
    font-weight: 800;
    color: #000;
    margin-top: 0;

    .fa-ils{
      font-size: 15px;
      margin-right: 3px;
    }
  }

  .cat-num{
    position: absolute;
    top: 20px;
    right: 20px;
    color: #777777;
    font-size: 14px;
    z-index: 10;
  }

  .btn-product-watch, .btn-product-cart{
    width: 115px;
    height: 30px;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    display: inline-block;
    padding-right: 35px;
    position: relative;
    line-height: 26px;
    transition: 0.2s;

    &:after{
      position: absolute;
      right: 8px;
    }

    &:hover{
      color: #fff;

      &:after{
        filter: brightness(0) invert(1);
      }
    }
  }

  .btn-product-watch{
    color: #000;
    border-color: #000;
    float: right;
    margin-right: 20px;

    &:after{
      content: url(/assets/images/product_glass.png);
      top: 4px;
    }

    &:hover{
      background-color: #000;
    }
  }

  .btn-product-cart{
    color: #e41e26;
    border-color: #e41e26;
    float: left;
    margin-left: 20px;

    &:after{
      content: url(/assets/images/product_cart.png);
      top: 2px;
    }

    &:hover{
      background-color: #e41e26;
    }
  }
}
