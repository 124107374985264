.info-box {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 140;
    padding: 20px 0;


  .info-box-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.85;
    top: 0;
    left: 0;
  } //info-box-overlay

  .info-box-content{
    max-width: 730px;
    padding: 15px;
    color: #000;
    text-align: center;
    margin: 0 auto;
    position: relative;

    .info-box-text{
      p{
        font-size: 20px;
        margin-bottom: 25px;

        @include respond-to('medium'){
          font-size: 15px;
          margin-bottom: 15px;
        }

        &:last-of-type{
          margin-bottom: 30px;
        } //last-of-type
      } //p
    } //info-box-text

    img{
      margin: 40px 0 55px;

      @include respond-to('medium'){
        margin: 20px 0 25px;
      }
    } //img

    a{
      color: #fff;
      border: 1px solid #8E0E13;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 700;
      padding: 7px 12px;
      position: relative;
      background: #8E0E13;
    } //a
  } //info-box-content
} //info-box
